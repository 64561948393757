import { Container } from '@finn/atoms';
import { Button } from '@finn/design-system';
import { CheckoutStep } from '@finn/ua-auth';
import { errorMessages } from '@finn/ua-constants';
import {
  checkoutErrorEvent,
  interactionTrackingEvent,
  TrackingEventName,
} from '@finn/ui-modules';
import {
  captureMessage,
  Locale,
  parseToHtml,
  useCurrentLocale,
} from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import get from 'lodash/get';
import Link from 'next/link';
import React, { useEffect } from 'react';

import HeadTags from '~/components/HeadTags';
import SpacingBox from '~/components/SpacingBox';
import Page from '~/layouts/Default';
import { ErrorPageData } from '~/types/cosmicModules';
import ProductSlider from '~/ui-modules/ProductSlider';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  button: {
    padding: theme.spacing(0, 1),
    height: theme.spacing(7),
    marginTop: theme.spacing(3),
    width: 185,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

interface IProps {
  pageData: ErrorPageData;
  statusCode: number;
  requestId?: string;
  error: Error | null;
  checkoutStep?: CheckoutStep;
}

const ErrorPage: React.FunctionComponent<IProps> = ({
  checkoutStep,
  pageData,
  statusCode,
  error,
  requestId,
}) => {
  const classes = useStyles();
  const { locale } = useCurrentLocale();
  const type = statusCode === 404 ? '404' : '500';
  const title = parseToHtml(
    get(pageData, 'metadata.content.headlines.title', '')
  );
  const description = parseToHtml(
    get(pageData, `metadata.headlines.description_${type}`, '')
  );

  const buttonText = parseToHtml(
    get(pageData, 'metadata.content.button.text', '')
  );
  const buttonHref = get(pageData, 'metadata.content.button.href', '');

  useEffect(() => {
    if (checkoutStep) {
      console.error(`Server side error ${error} Request ID: ${requestId}`);

      checkoutErrorEvent(
        errorMessages.PAGE_LOAD_FAILED,
        checkoutStep,
        error?.message,
        statusCode,
        requestId
      );
    } else {
      interactionTrackingEvent(TrackingEventName.Error, {});
    }

    if (error) {
      captureMessage(error, statusCode);
    }
  }, []);

  const seoHeader = get(pageData, `metadata.content.seo_header_${type}`, {});

  return (
    <Page data={pageData}>
      <HeadTags seoHeader={seoHeader} isNoIndexNoFollow={true} />
      <SpacingBox>
        <Container>
          <div className={classes.titleWrapper}>
            <h1 className="mobile-t1-semibold sm:web-t1-semibold mb-2">
              {title}
            </h1>
            <p className="ml-1">{description}</p>
          </div>
        </Container>
        {locale !== Locale.ENGLISH_GERMANY && (
          <ProductSlider
            title={get(pageData, 'metadata.content.vehicles_slider_title', '')}
          />
        )}
        <Container>
          <Box marginTop={6}>
            <Link href={buttonHref}>
              <Button href={buttonHref}>{buttonText}</Button>
            </Link>
          </Box>
        </Container>
      </SpacingBox>
    </Page>
  );
};

export default ErrorPage;
